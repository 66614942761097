$(document).on("turbolinks:load", function() {

  $('#questionnaire_image_url').change(function() {
    var filename = $('#questionnaire_image_url').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    $('#fileinput').html(filename);
  });

  $('body').on('click', '.js-btn-reset-questionnaire', function() {
    window.location.href = '/notifications';
  });

  $('body').on('click', '.js-btn-delete-questionnaire', function() {
    sweetAleartDeletequestionnaire($(this).data('id'));
  });

  function sweetAleartResetquestionnaire() {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        window.location.href = '/notifications'
      }
    });
  }

  function sweetAleartDeletequestionnaire(questionnaire_id) {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        $.ajax({
          method: 'DELETE',
          url: `/questionnaires/${questionnaire_id}`
        });
      }
    });
  }

  // count text in questionnaire
  var text_length = document.getElementsByClassName("form_questionnaire")

  $('#questionnaire_title').on('keyup', function() {
    $('#qt_title').text($(this).val().length + ' / 80 文字')
  });

  if(text_length.length !== 0){
    $('#qt_title').text($('#questionnaire_title').val().length + ' / 80 文字')
  }
});
