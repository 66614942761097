$(function(){
  $('.js-upload-image-notice').click(function(){
    $('#notice_image_url').trigger('click');
  });

  $('.js-upload-image-questionnaire').click(function(){
    $('#questionnaire_image_url').trigger('click');
  });

  $('.js-upload-pdf-notice').click(function(){
    $('#file-pdf').trigger('click');
  });

  $('.js-upload-pdf-solution').click(function(){
    $('#file-pdf').trigger('click');
  });
});
