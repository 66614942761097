$(document).on("turbolinks:load", function() {
  $('#partner_brand_icon_url').change(function() {
    var filename = $('#partner_brand_icon_url').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    $('#partner-img-brand_icon_url').html(filename);
  });

  // イベントハンドラ (ヘッダー画像が変更されたとき)
  $('#partner_home_configuration_attributes_header_image_url').change(function() {
    var filename = $('#partner_home_configuration_attributes_header_image_url').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    $('#partner-img-header_image_url').html(filename);
    $('#delete_header_image_url').hide();
  });

  // イベントハンドラ (メイン画像が変更されたとき)
  $('#partner_home_configuration_attributes_main_image_url').change(function() {
    var filename = $('#partner_home_configuration_attributes_main_image_url').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    $('#partner-img-main_image_url').html(filename);
    $('#delete_main_image_url').hide();
  });

  $('body').on('click', '.js-btn-coppy-partner', function() {
    var inputs = $('.js_copy_all_field_partner input');

    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i].name !== 'partner[partner_cd]'){
        if (inputs[i].name == 'partner[corporation_name]'){
          var billName = inputs[i].name.replace(/partner\[corporation_name\]/gi, 'partner[invoice_corporation_name]');
        }
        else if (inputs[i].name == 'partner[corporation_zip]'){
          var billName = inputs[i].name.replace(/partner\[corporation_zip\]/gi, 'partner[invoice_zip]');
        }
        else if (['partner[corporation_address1]',
                  'partner[corporation_address2]',
                  'partner[corporation_address3]',
                  'partner[corporation_address4]'].indexOf(inputs[i].name) > -1){
          var billName = inputs[i].name.replace(/partner\[corporation/gi, 'partner[invoice');
        }
        else{
          var billName = inputs[i].name.replace(/partner\[person/gi, 'partner[invoice');
        }

        if($('.js-btn-coppy-partner').prop( "checked" ) == true) {
          $('input[name="' + billName + '"]').val(inputs[i].value);
          $('input[name="' + billName + '"]').attr('readonly', true);
        }else{
          $('input[name="' + billName + '"]').val('');
          $('input[name="' + billName + '"]').attr('readonly', false);
        }
      }
    };
  });

  $(document).ready(function() {
    var inputs = $('.js_copy_all_field_partner input');

    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i].name == 'partner[corporation_name]'){
        var billName = inputs[i].name.replace(/partner\[corporation_name\]/gi, 'partner[invoice_corporation_name]');
      }
      else if (inputs[i].name == 'partner[corporation_zip]'){
        var billName = inputs[i].name.replace(/partner\[corporation_zip\]/gi, 'partner[invoice_zip]');
      }
      else if (['partner[corporation_address1]',
                'partner[corporation_address2]',
                'partner[corporation_address3]',
                'partner[corporation_address4]'].indexOf(inputs[i].name) > -1){
        var billName = inputs[i].name.replace(/partner\[corporation/gi, 'partner[invoice');
      }
      else{
        var billName = inputs[i].name.replace(/partner\[person/gi, 'partner[invoice');
      }

      if($('.js-btn-coppy-partner').prop( "checked" ) == true && inputs[i].name !== 'partner[partner_cd]') {
        $('input[name="' + billName + '"]').val(inputs[i].value);
        $('input[name="' + billName + '"]').attr('readonly', true);
      }
    };
  });

  $("#searchZipcode").click(function(){
    $('#partner_corporation_address1').val('');
    $('#partner_corporation_address2').val('');
    var zipcode = $('#partner_corporation_zip').val();
    var url = 'https://geoapi.heartrails.com/api/json?method=searchByPostal&postal=' + zipcode;
    $.ajax({
      url: url,
      type: 'GET'
    }).done(function(data){
      if (data['response'] && data['response']['location']){
        var location = data['response']['location'][0];
        if (location){
          $('#partner_corporation_address1').val(location['prefecture']);
          $('#partner_corporation_address2').val(location['city']);
          $('#partner_corporation_address3').val(location['town'].replace('一丁目', '').replace('二丁目', '').replace('丁目', ''));
        }
      }
    })
  });

  $("#searchZipcodeBill").click(function(){
    $('#partner_invoice_address1').val('');
    $('#partner_invoice_address2').val('');
    var zipcode = $('#partner_invoice_zip').val();
    var url = 'https://geoapi.heartrails.com/api/json?method=searchByPostal&postal=' + zipcode;
    $.ajax({
      url: url,
      type: 'GET'
    }).done(function(data){
      if (data['response'] && data['response']['location']){
        var location = data['response']['location'][0];
        if (location){
          $('#partner_invoice_address1').val(location['prefecture']);
          $('#partner_invoice_address2').val(location['city']);
          $('#partner_invoice_address3').val(location['town'].replace('一丁目', '').replace('二丁目', '').replace('丁目', ''));
        }
      }
    })
  });

  $("#store_list tbody tr").click(function() {
     var selected = $(this).hasClass("highlight");
     $("#store_list tbody tr").removeClass("highlight");
     if(!selected){
       $(this).addClass("highlight");
     }
  });

  $('body').on('click', '.js-btn-reset-partner', function() {
    window.location.href = '/partners';
  });

  $('body').on('click', '.js-btn-delete-partner', function() {
    sweetAleartDeletePartner($(this).data('id'));
  });

  function sweetAleartResetPartner() {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        window.location.href = '/partners'
      }
    });
  }

  function sweetAleartDeletePartner(partner_id) {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        $.ajax({
          method: 'DELETE',
          url: `/partners/${partner_id}`
        });
      }
    });
  }

  var cpm_pn = document.getElementsByClassName("partner-edit-1"),
      cpm_st = document.getElementsByClassName("stores-edit-dt");

  $('#partner_memo').on('keyup', function() {
    $('#count_partner_memo').text($(this).val().length + ' / 300 文字')
  });

  $('#store_access').on('keyup', function() {
    $('#count_store_access').text($(this).val().length + ' / 200 文字')
  });

  $('#store_summary').on('keyup', function() {
    $('#count_store_description').text($(this).val().length + ' / 40 文字')
  });

  $('#store_detail').on('keyup', function() {
    $('#count_store_description_detail').text($(this).val().length + ' / 2000 文字')
  });

  $('#store_memo').on('keyup', function() {
    $('#count_store_memo').text($(this).val().length + ' / 300 文字')
  });

  if(cpm_pn.length !== 0){
    $('#count_partner_memo').text($('#partner_memo').val().length + ' / 300 文字')
  }

  if(cpm_st.length !== 0){
    $('#count_store_access').text($('#store_access').val().length + ' / 200 文字')
    $('#count_store_description').text($('#store_summary').val().length + ' / 40 文字')
    $('#count_store_description_detail').text($('#store_detail').val().length + ' / 2000 文字')
    $('#count_store_memo').text($('#store_memo').val().length + ' / 300 文字')
  }

  $('body').on('click', '#load-more-partners', function() {
    let page = $(this).data('page')
    $.ajax({
      url: '/partners?page=' + page,
      type: 'get',
      success: function(data){
        $('#load_more_tbody_dtpr').append($(data).find('#load_more_tbody_dtpr tr'));
        $('#button_load_more_tdpr').html($(data).find('#button_load_more_tdpr a'));
        $('#data_size_partner_js').html("該当件数:" + $('#load_more_tbody_dtpr tr').length + "件");
      }
    })
    return false;
  })

  $(".js_export_csv").on("click", function() {
    var url = new URL(location.href);
    url.searchParams.set("format", "csv");
    location.href = url
  });
});
