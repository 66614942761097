$(document).on("turbolinks:load", function() {
  $("#sale_list tbody tr").click(function() {
     var selected = $(this).hasClass("highlight");
     $("#sale_list tbody tr").removeClass("highlight");
     if(!selected){
       $(this).addClass("highlight");
     }
  });

  // load more
  $('body').on('click', '#load-more-sales', function() {
    let page = $(this).data('page')
    $.ajax({
      url: '/sales?page=' + page,
      type: 'get',
      success: function(data){
        $('#load_more_tbody_sale').append($(data).find('#load_more_tbody_sale tr'));
        $('#button_load_more_td_sale').html($(data).find('#button_load_more_td_sale a'));
        var rowCount = $('#load_more_tbody_sale tr').length;
        $('#data_size').html("該当件数:" + rowCount + "件");
      }
    })
    return false;
  })

  // expand
  $(".js-sale-detail-expand .icon-expand").on("click", function() {
    var $iconExpand = $(this).parent().find(".icon-expand");
    var class_name = "." + $(this).parent().find(".expand-sale-detail").attr('data-id');

    if ($.inArray("fa-caret-down", $iconExpand[0].className.split(/\s+/)) != -1) {
      var classExpand = "fa-caret-up";
    } else {
      var classExpand = "fa-caret-down";
    }
    $iconExpand.removeClass("fa-caret-down fa-caret-up");
    $iconExpand.addClass(classExpand);
    $(this).parent().find(class_name).slideToggle();
  });
});
