$(document).on("turbolinks:load", function () {

    $(".list-btn-action").hide();
    $(".preview_send").hide();
    $("#btn_preview").on("click", function () {
        const list_check_id = [
            "type_corporation_admin",
            "type_corporation_user",
            "type_corporation_manager",
            "type_corporation_invoice_addressee",
            "type_personal_user",
            "type_partner_system_staff",
            "type_partner_manager",
            "type_partner_invoice_addressee",
            "notice_mail_refusal_flag"

        ];
        let list_check = false;
        let validate_data = false;
        for (let i = 0; i < list_check_id.length; i++) {
            if ($('#' + list_check_id[i]).is(':checked')) {
                $(".span_text_checkbox_" + list_check_id[i])
                    .removeClass("type-uncheck")
                    .addClass("type-ischeck");
                list_check = true;
            } else {
                $(".span_text_checkbox_" + list_check_id[i])
                    .removeClass("type-ischeck")
                    .addClass("type-uncheck");
            }
        }

        if ($("#subject").val() === "") {
            $(".message_subject_required").removeClass("d-none").addClass("d-block");
            validate_data = true;
        } else {
            $(".message_subject_required").removeClass("d-block").addClass("d-none");
            /*if ($("#subject").val().length > 120) {
                $(".message_subject_length").removeClass("d-none").addClass("d-block");
                validate_data = true;
            } else {
                $(".message_subject_length").removeClass("d-block").addClass("d-none");
            }*/
        }

        if ($("#content").val() === "") {
            $(".message_content_required").removeClass("d-none").addClass("d-block");
            validate_data = true;
        } else {
            $(".message_content_required").removeClass("d-block").addClass("d-none");
           /* if ($("#content").val().length > 3000) {
                $(".message_content_length").removeClass("d-none").addClass("d-block");
                validate_data = true;
            } else {
                $(".message_content_length").removeClass("d-block").addClass("d-none");
            }*/
        }

        if (!list_check) {
            $(".message_list_mails_required").removeClass("d-none").addClass("d-block");
            validate_data = true;
        } else {
            $(".message_list_mails_required").removeClass("d-block").addClass("d-none");
        }

        if (validate_data) {
            return false;
        }

        $(".form_send").hide();
        $(".preview_send").removeClass("d-none").show();
        $(".list-btn-preview").hide();
        $(".list-btn-action").show();
        $(".subject").html($("#subject").val());
        $(".content").html($("#content").val().replace(new RegExp('\r?\n','g'), '<br/>') + '<br><br/>' + $("#signature").html());


    });

    $("#btn_sendmail").on("click",function () {
        Swal.fire(swalSendMail).then(function(result) {
            if (result.value) {
                $("#btn_sendmail").css('pointer-events', 'none');
                $("#btn_back").css('pointer-events', 'none');
                $("#f_send_mails").submit();
            }
        });
    })

    $("#btn_back").on("click", function () {
        $(".form_send").show();
        $(".preview_send").hide();
        $(".list-btn-preview").show();
        $(".list-btn-action").hide();
    });
    $(document).on("keydown", ":input:not(textarea)", function(event) {
        return event.key != "Enter";
    });

    $('body').on('click', '#notice_mail_refusal_flag', function() {
        if ($(this).prop( "checked" ) == true) {
            $("#signature").html($("#mst_email_broadcast_signature_important").val());
        } else {
            $("#signature").html($("#mst_email_broadcast_signature_no_important").val());
        }
    });
});
