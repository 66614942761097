$(function(){
  $('#file-pdf').change(function() {
    var filename = $('#file-pdf').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    $('#fileinput').html(filename);
  });

  $('#solution_icon_on_url').change(function() {
    var filename = $('#solution_icon_on_url').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    $('#img-on-input').html(filename);
  });

  $('#solution_icon_off_url').change(function() {
    var filename = $('#solution_icon_off_url').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    $('#img-off-input').html(filename);
  });

  $("#solution_list tbody tr").click(function() {
     var selected = $(this).hasClass("highlight");
     $("#solution_list tbody tr").removeClass("highlight");
     if(!selected){
       $(this).addClass("highlight");
     }
  });

  $('body').on('click', '.js-btn-reset-solution', function() {
    window.location.href = '/solutions';
  });

  $('body').on('click', '.js-btn-delete-solution', function() {
    sweetAleartDeletesolution($(this).data('id'));
  });

  function sweetAleartResetsolution() {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        window.location.href = '/solutions'
      }
    });
  }

  function sweetAleartDeletesolution(solution_id) {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        $.ajax({
          method: 'DELETE',
          url: `/solutions/${solution_id}`
        });
      }
    });
  }

  // load more
  $('body').on('click', '#load-more-solutions', function() {
    let page = $(this).data('page')
    $.ajax({
      url: '/solutions?page=' + page,
      type: 'get',
      success: function(data){
        $('#load_more_tbody_sl').append($(data).find('#load_more_tbody_sl tr'));
        $('#button_load_more_td_sl').html($(data).find('#button_load_more_td_sl a'));
        var rowCount = $('#load_more_tbody_sl tr').length;
        $('#data_size').html("該当件数:" + rowCount + "件");
      }
    })
    return false;
  })
});
