$(document).on("turbolinks:load", function() {
  $('#file-pdf').change(function() {
    var filename = $('#file-pdf').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    $('#pdf-input').html(filename);
  });

  $('#notice_image_url').change(function() {
    var filename = $('#notice_image_url').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    $('#image-input').html(filename);
  });

  $('body').on('click', '.js-btn-reset-notice', function() {
    window.location.href = '/notifications';
  });

  $('body').on('click', '.js-btn-delete-notice', function() {
    sweetAleartDeletenotice($(this).data('id'));
  });

  function sweetAleartResetnotice() {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        window.location.href = '/notifications'
      }
    });
  }

  function sweetAleartDeletenotice(notice_id) {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        $.ajax({
          method: 'DELETE',
          url: `/notices/${notice_id}`
        });
      }
    });
  }

  // load more
  $('body').on('click', '#load-more-notifications', function() {
    let page = $(this).data('page')
    $.ajax({
      url: '/notifications?page=' + page,
      type: 'get',
      success: function(data){
        $('#load_more_tbody_ann').append($(data).find('#load_more_tbody_ann tr'));
        $('#button_load_more_td_ann').html($(data).find('#button_load_more_td_ann a'));
        var rowCount = $('#load_more_tbody_ann tr').length;
        $('#data_size').html("該当件数:" + rowCount + "件");
      }
    })
    return false;
  })

  // count text in notice
  var text_length = document.getElementsByClassName("form_notice")

  $('#notice_title').on('keyup', function() {
    $('#nt_title').text($(this).val().length + ' / 80 文字')
  });

  $('#notice_main_message').on('keyup', function() {
    $('#nt_main_message').text($(this).val().length + ' / 2000 文字')
  });

  if(text_length.length !== 0){
    $('#nt_title').text($('#notice_title').val().length + ' / 80 文字')
    $('#nt_main_message').text($('#notice_main_message').val().length + ' / 2000 文字')
  }

  if($('.notification').length > 0){
    $('#js_notice_partner_id').on('change', function () {
      var partner_id = $('#js_notice_partner_id').val();
      $('#js_notice_store_id').html('');
      $.ajax({
        type: 'GET',
        url: '/notifications/render_store_by_partner',
        data: {'js_partner_id': partner_id},
        dataType: 'json',
        success: function(data) {
          $('#js_notice_store_id').append(`<option value="">指定しない</option>`);
          data["store"].forEach(function(value) {
            if ($('#js_notice_store_id').attr('value') == value[1]) {
              $('#js_notice_store_id').append(`<option value="` + value[1] + `" selected>` + value[0] + `</option>`)
            }else{
              $('#js_notice_store_id').append(`<option value="` + value[1] + `">` + value[0] + `</option>`)
            }
          });
        }
      });
    });

    if ($('#js_notice_partner_id').val() != undefined) {
      var partner_id = $('#js_notice_partner_id').val();
      $.ajax({
        type: 'GET',
        url: '/notifications/render_store_by_partner',
        data: {'js_partner_id': partner_id},
        dataType: 'json',
        success: function(data) {
          data["store"].forEach(function(value) {
            if ($('#js_notice_store_id').attr('value') == value[1]) {
              $('#js_notice_store_id').append(`<option value="` + value[1] + `" selected>` + value[0] + `</option>`)
            }else{
              $('#js_notice_store_id').append(`<option value="` + value[1] + `">` + value[0] + `</option>`)
            }
          });
        }
      });
    }
  }
});
