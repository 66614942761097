$(document).on("turbolinks:load", function() {
  if(document.getElementById("jsPartner") !== null) {
    var jsPartner = document.getElementById("jsPartner")
    var storeAddressJs = document.getElementById("storeAddressJs").innerHTML;
    var instance_of_booking = '';
    if (jsPartner.classList.contains('js_booking_search')) {
      instance_of_booking = 'booking'
    }
    var storeNameJs = document.getElementById("storeNameJs").innerHTML;

    var partnerNameJs = document.getElementById("partnerNameJs").innerHTML;
    // render store_name
    $('#jsStoreAddress1').on('change', function () {
      $('#jsStoreName').html(`<option value="">指定しない</option>`);
      var partner_id = $('#jsPartner option:checked').val();

      $.ajax({
        type: 'GET',
        url: '/stores/render_store_data_by_search?instance_of=' + instance_of_booking,
        data: {'store_address': this.value, 'partner_id': partner_id},
        dataType: 'json',
        success: function (data) {
          data["store_names"].forEach(function (value) {
            $('#jsStoreName').append(`<option value="` + value[1] + `">` + value[0] + `</option>`)
          });
        }
      });
    });

    $('#jsPartner').on('change', function () {
      $('#jsStoreName').html(`<option value="">指定しない</option>`);
      $('#jsStoreAddress1').html(`<option value="">指定しない</option>`);
      var store_address = $('#jsStoreAddress1 option:checked').val()
      $.ajax({
        type: 'GET',
        url: '/stores/render_store_data_by_search?instance_of=' + instance_of_booking,
        data: {'store_address': store_address, 'partner_id': this.value},
        dataType: 'json',
        success: function (data) {
          data["store_names"].forEach(function (value) {
            $('#jsStoreName').append(`<option value="` + value[1] + `">` + value[0] + `</option>`)
          });

          data["store_address"].forEach(function (value) {
            $('#jsStoreAddress1').append(`<option value="` + value[1] + `">` + value[0] + `</option>`)
          });
          $("#jsPartnerBrandName").val(data['js_brands']);
        }
      });
    });

    //cache store name
    $('#jsStoreName').html(`<option value="">指定しない</option>`);
    $('#jsStoreAddress1').html(`<option value="">指定しない</option>`);

    $.ajax({
      type: 'GET',
      url: '/stores/render_store_data_by_search?instance_of=' + instance_of_booking,
      data: {'store_address': storeAddressJs, 'partner_id': partnerNameJs},
      dataType: 'json',
      success: function (data) {
        data["store_names"].forEach(function (value) {
          if (storeNameJs == value[1]) {
            $('#jsStoreName').append(`<option value="` + value[1] + `" selected>` + value[0] + `</option>`)
          } else {
            $('#jsStoreName').append(`<option value="` + value[1] + `">` + value[0] + `</option>`)
          }
        });

        data["store_address"].forEach(function (value) {
          if (storeAddressJs == value[1]) {
            $('#jsStoreAddress1').append(`<option value="` + value[1] + `" selected>` + value[0] + `</option>`)
          } else {
            $('#jsStoreAddress1').append(`<option value="` + value[1] + `">` + value[0] + `</option>`)
          }
        });

        $("#jsPartnerBrandName").val(data['js_brands']);
      }
    });
  }
});