$(document).on("turbolinks:load", function() {
  if ($('#system_staff_mst_staff_authority_id').val() != '3'){
    $('#system_staff_partner_id').val('');
    $('#system_staff_partner_id').attr('disabled', 'disabled');
  }

  $('#system_staff_mst_staff_authority_id').on('change', function() {
    if ($(this).val() != "3") {
      $('#system_staff_partner_id').val('');
      $('#system_staff_partner_id').attr('disabled', 'disabled');
    } else {
      $('#system_staff_partner_id').removeAttr('disabled');
    }
  })

  $("#system_staff_list tbody tr").click(function() {
     var selected = $(this).hasClass("highlight");
     $("#system_staff_list tbody tr").removeClass("highlight");
     if(!selected){
       $(this).addClass("highlight");
     }
  });

  $('body').on('click', '.js-btn-reset-system_staff', function() {
    window.location.href = '/system_staffs';
  });

  $('body').on('click', '.js-btn-delete-system_staff', function() {
    sweetAleartDeletesystem_staff($(this).data('id'));
  });

  $('body').on('click', '.js-btn-reset-password', function() {
    sweetAleartResetPasswordSystemStaff($(this).data('id'));
  });

  function sweetAleartDeletesystem_staff(system_staff_id) {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        $.ajax({
          method: 'DELETE',
          url: `/system_staffs/${system_staff_id}`
        });
      }
    });
  }

  function sweetAleartResetPasswordSystemStaff(system_staff_id) {
    Swal.fire(swalResetPassword).then(function(result) {
      if (result.value) {
        $.ajax({
          method: 'POST',
          url: `/system_staffs/${system_staff_id}/reset_password`,
          error : function (e) {
            if(e.status === 401) {
              location.href = "/system_staffs/login"
            }
          }
        });
      }
    });
  }

  // load more
  $('body').on('click', '#load-more-system_staffs', function() {
    let page = $(this).data('page')
    $.ajax({
      url: '/system_staffs?page=' + page,
      type: 'get',
      success: function(data){
        $('#load_more_tbody_system_staff').append($(data).find('#load_more_tbody_system_staff tr'));
        $('#button_load_more_td_system_staff').html($(data).find('#button_load_more_td_system_staff a'));
        var rowCount = $('#load_more_tbody_system_staff tr').length;
        $('#data_size').html("該当件数:" + rowCount + "件");
      }
    })
    return false;
  })
});
