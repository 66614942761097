$(document).on("turbolinks:load", function() {

  $(".js-export-visit_history-csv").on("click", function() {
    var url = new URL(location.href);
    url.searchParams.set("format", "csv");
    location.href = url
  });

  $(".save-modification-time").on("click", function(){
    var data_change = [];
    var div_time = $(".table_visit_history tr td .update_time");
    var i;
    for (i = 0; i < div_time.length; i++) { 
      if ($(div_time[i]).attr('data-edited') === 'edited'){
        var type = $(div_time[i]).attr('data-type');
        var vh_id = $(div_time[i]).attr('data-id');
        var time = $(div_time[i]).val();
        data_change.push({
          type: type,
          id: vh_id,
          time: time
        });
      }
    }
    if (data_change.length === 0){
      return
    }

    $.ajax({
      method: 'POST',
      url: '/visit_histories/update_time',
      dataType: 'json',
      data: {data: JSON.stringify(data_change)},
      success: function(result) {
        if(result.data) {
          toastr.success('修正時刻の保存に成功しました。');
        } else {
          toastr.error('修正時刻の保存に失敗しました。');
        }
        window.location.href = '/visit_histories'
      }
    });
  })

  $("#date_year option").each(function(){
    if($(this).val() != '' && $(this).val() != null && $(this).val() != 'undefined'){
      $(this).html($(this).val() + '年');
    }
  });

  $("#date_month option").each(function(){
    if($(this).val() != '' && $(this).val() != null && $(this).val() != 'undefined'){
      $(this).html($(this).val() + '月');
    }
  });

  $("#date_day option").each(function(){
    if($(this).val() != '' && $(this).val() != null && $(this).val() != 'undefined'){
      $(this).html($(this).val() + '日');
    }
  });

  $( "#date_month" ).change(function() {
    var cur_month = $( "#date_month" ).val();
    var cur_year = $( "#date_year" ).val();
    daysInMonth = new Date(cur_year, cur_month, 0).getDate();
    arr_days = Array.from({length: daysInMonth}, (_, i) => i + 1)

    $('#date_day').html(`<option value=""></option>`);
    $.each( arr_days, function( key, value ) {
      $('#date_day').append(`<option value="${value}">${value}日</option>`)
    });
  })

  $( "#date_year" ).change(function() {
    var cur_month = $( "#date_month" ).val();
    var cur_year = $( "#date_year" ).val();
    daysInMonth = new Date(cur_year, cur_month, 0).getDate();
    arr_days = Array.from({length: daysInMonth}, (_, i) => i + 1)

    $('#date_day').html(`<option value=""></option>`);
    $.each( arr_days, function( key, value ) {
      $('#date_day').append(`<option value="${value}">${value}日</option>`)
    });
    $( "#date_month" ).val("");
  })

  var selected_value = $('#q_selected_day').val();
  if (selected_value != undefined) {
    var cur_month = $( "#date_month" ).val();
    var cur_year = $( "#date_year" ).val();
    daysInMonth = new Date(cur_year, cur_month, 0).getDate();
    arr_days = Array.from({length: daysInMonth}, (_, i) => i + 1)

    $('#date_day').html(`<option value=""></option>`);
    $.each( arr_days, function( key, value ) {
      if (parseInt(selected_value) == value) {
        $('#date_day').append(`<option value="${value}" selected = "selected">${value}日</option>`)
      } else {
        $('#date_day').append(`<option value="${value}">${value}日</option>`)
      }
    });
  }
});
