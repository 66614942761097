$(document).on("turbolinks:load", function() {
  $('.books-edit-dt #booking_user_code').on('input',function(){
    var user_code = $('#booking_user_code').val();
    $.ajax({
            url: '/bookings/get_fullname',
            type: 'POST',
            data: {
              user_code: user_code
            }
          }).done(function(result) {
            $('#book_user_name').html(result.data);
          });
  })

  $('#address_select').change(function () {
    var name = $(this).find(':selected').val();
    address_to_store(name);
    if (name == null || name == ""){
      store_to_ws(null);
    }
  });

  $('#store_select').change(function () {
    var name = $(this).find(':selected').val();
    store_to_ws(name);
  });

  function address_to_store (name) {
    $.ajax({
        type: 'POST',
        url: '/bookings/address_to_store',
        data: {
          'address1': name
        },
        success: function (data) {
          $('#store_select').empty();

          if(data != undefined){
            for (var i = 0; i < data.length; i++) {
              $('#store_select').append('<option id=' + data[i].id + ' value=' + data[i].id + '>' + data[i].name + '</option>');
            }
          }

          store_to_ws($('#store_select').find(':selected').attr('id'));
        }
    });
  }

  function store_to_ws (store_id) {
    $.ajax({
        type: 'POST',
        url: '/bookings/store_to_workspace',
        data: {
            'store_id': store_id
        },
        success: function (data) {
          $('#workspace_select').empty();

          if(data != undefined){
            for (var i = 0; i < data.length; i++) {
              $('#workspace_select').append('<option id=' + data[i].id + ' value=' + data[i].id + '>' + data[i].name + '</option>');
            }
          }
        }
    });
  }

  $(".js-export-book-csv").on("click", function() {
    var url = new URL(location.href);
    url.searchParams.set("format", "csv");
    location.href = url
  });

  $('body').on('click', '#load-more-bookings', function() {
    let page = $(this).data('page')
    $.ajax({
      url: '/bookings?page=' + page,
      type: 'get',
      success: function(data){
        $('#load_more_tbody_dtbk').append($(data).find('#load_more_tbody_dtbk tr'));
        $('#button_load_more_tdbk').html($(data).find('#button_load_more_tdbk a'));
        $('#data_size_booking_js').html("該当件数:" + $('#load_more_tbody_dtbk tr').length + "件");
      }
    })
    return false;
  });

    $("#booking_memo").on("keyup", function () {
        count_memo();
    });

    function count_memo() {
        $("#count_book_memo").html("0 / 200 文字");
        if ($("#booking_memo").val() !== undefined && $("#booking_memo").val().length > 0) {
            var leng = $("#booking_memo").val().length;
            $("#count_book_memo").html(leng + " / 200 文字");
        }
    }

    count_memo();
});
