$(function(){
  $("#committee_list tbody tr").click(function() {
     var selected = $(this).hasClass("highlight");
     $("#committee_list tbody tr").removeClass("highlight");
     if(!selected){
       $(this).addClass("highlight");
     }
  });

  $('body').on('click', '.js-btn-reset-committee', function() {
    if(areAllInputsEmpty('input.form-control') && areAllInputsEmpty('input.input')
      && areAllInputsEmpty('.select select')) {
      window.location.href = '/mst_committees';
    } else {
      sweetAleartResetcommittee();
    }
  });

  $('body').on('click', '.js-btn-delete-committee', function() {
    sweetAleartDeletecommittee($(this).data('id'));
  });

  function sweetAleartResetcommittee() {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        window.location.href = '/mst_committees'
      }
    });
  }

  function sweetAleartDeletecommittee(committee_id) {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        $.ajax({
          method: 'DELETE',
          url: `/mst_committees/${committee_id}`
        });
      }
    });
  }
});
