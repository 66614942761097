$(document).on("turbolinks:load", function() {
$(document).ready(function () {
  $(document).on('click', '.js-nav-link', function () {
    $(this).toggleClass('active').parent().next('.js-nav-dropdown').slideToggle();
  });

  $('.js-toggle-btn').on('click',  function () {
    $('.js-sidebar').toggleClass('active');
    $(this).addClass("d-none");
  });

  var pathName = window.location.pathname;

  $('.js-nav-dropdown .sidebar__nav-link').each((index, item) => {
    // if(gon.path && gon.path == $(item).attr('href')) toggleNavBar(item);

    if($(item).attr('href') == pathName) toggleNavBar(item);
  })

});

function toggleNavBar(item) {
  $(item).closest('.js-parent-nav-link').find('.js-nav-link').addClass('active');
  $(item).closest('.js-nav-dropdown').show();
}

});