$(document).on("turbolinks:load", function() {
  $("#account_list tbody tr").click(function() {
     var selected = $(this).hasClass("highlight");
     $("#account_list tbody tr").removeClass("highlight");
     if(!selected){
       $(this).addClass("highlight");
     }
  });

  $('body').on('click', '.js-btn-reset-account', function() {
    window.location.href = '/accounts';
  });

  $('body').on('click', '.js-btn-delete-account', function() {
    sweetAleartDeleteaccount($(this).data('id'));
  });

  function sweetAleartResetaccount() {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        window.location.href = '/accounts'
      }
    });
  }

  function sweetAleartDeleteaccount(account_id) {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        $.ajax({
          method: 'DELETE',
          url: `/accounts/${account_id}`
        });
      }
    });
  }

  $("#searchZipcode_in_account").click(function(){
    $('#account_corporation_address1').val('');
    $('#account_corporation_address2').val('');
    var zipcode = $('#account_corporation_zip').val();
    var url = 'https://geoapi.heartrails.com/api/json?method=searchByPostal&postal=' + zipcode;
    $.ajax({
      url: url,
      type: 'GET'
    }).done(function(data){
      if (data['response'] && data['response']['location']){
        var location = data['response']['location'][0];
        if (location){
          $('#account_corporation_address1').val(location['prefecture']);
          $('#account_corporation_address2').val(location['city']);
          $('#account_corporation_address3').val(location['town'].replace('一丁目', '').replace('二丁目', '').replace('丁目', ''));
        }
      }
    })
  });

  $("#searchZipcodeBill_in_account").click(function(){
    $('#account_invoice_address1').val('');
    $('#account_invoice_address2').val('');
    var zipcode = $('#account_invoice_zip').val();
    var url = 'https://geoapi.heartrails.com/api/json?method=searchByPostal&postal=' + zipcode;
    $.ajax({
      url: url,
      type: 'GET'
    }).done(function(data){
      if (data['response'] && data['response']['location']){
        var location = data['response']['location'][0];
        if (location){
          $('#account_invoice_address1').val(location['prefecture']);
          $('#account_invoice_address2').val(location['city']);
          $('#account_invoice_address3').val(location['town'].replace('一丁目', '').replace('二丁目', '').replace('丁目', ''));
        }
      }
    })
  });

  // logic account form: select fill bill info
  $('#account_invoice_person_same_flag').click(function(){
    var inputs = $('.account .js-copy-all-field input');

    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i].name == 'account[corporation_name]'){
        var billName = inputs[i].name.replace(/account\[corporation_name\]/gi, 'account[invoice_corporation_name]');
      }
      else if (inputs[i].name == 'account[person_department]'){
        var billName = inputs[i].name.replace(/account\[person_department\]/gi, 'account[invoice_department]');
      }
      else if (inputs[i].name == 'account[person_position]'){
        var billName = inputs[i].name.replace(/account\[person_position\]/gi, 'account[invoice_position]');
      }
      else if (inputs[i].name == 'account[corporation_name_kana]'){
        var billName = inputs[i].name.replace(/account\[corporation_name_kana\]/gi, 'account[invoice_corporation_name_kana]');
      }
      else if (inputs[i].name == 'account[corporation_zip]'){
        var billName = inputs[i].name.replace(/account\[corporation_zip\]/gi, 'account[invoice_zip]');
      }
      else if (inputs[i].name == 'account[person_family_name]'){
        var billName = inputs[i].name.replace(/account\[person_family_name\]/gi, 'account[invoice_family_name]');
      }
      else if (inputs[i].name == 'account[person_first_name]'){
        var billName = inputs[i].name.replace(/account\[person_first_name\]/gi, 'account[invoice_first_name]');
      }
      else if (inputs[i].name == 'account[person_first_name_kana]'){
        var billName = inputs[i].name.replace(/account\[person_first_name_kana\]/gi, 'account[invoice_first_name_kana]');
      }
      else if (inputs[i].name == 'account[person_family_name_kana]'){
        var billName = inputs[i].name.replace(/account\[person_family_name_kana\]/gi, 'account[invoice_family_name_kana]');
      }
      else if (inputs[i].name == 'account[person_phone]'){
        var billName = inputs[i].name.replace(/account\[person_phone\]/gi, 'account[invoice_phone]');
      }
      else if (inputs[i].name == 'account[person_email]'){
        var billName = inputs[i].name.replace(/account\[person_email\]/gi, 'account[invoice_email]');
      }
      else{
        var billName = inputs[i].name.replace(/account\[corporation/gi, 'account[invoice');
      }

      if($('#account_invoice_person_same_flag').prop( "checked" ) == true) {
        $('input[name="' + billName + '"]').val(inputs[i].value);
        $('input[name="' + billName + '"]').attr('readonly', true);
      }else{
        $('input[name="' + billName + '"]').val('');
        $('input[name="' + billName + '"]').attr('readonly', false);
      }
    };
  });

  // init copy value in bill
  $(document).ready(function() {
    var inputs = $('.account .js-copy-all-field input');

    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i].name == 'account[corporation_name]'){
        var billName = inputs[i].name.replace(/account\[corporation_name\]/gi, 'account[invoice_corporation_name]');
      }
      else if (inputs[i].name == 'account[person_department]'){
        var billName = inputs[i].name.replace(/account\[person_department\]/gi, 'account[invoice_department]');
      }
      else if (inputs[i].name == 'account[person_position]'){
        var billName = inputs[i].name.replace(/account\[person_position\]/gi, 'account[invoice_position]');
      }
      else if (inputs[i].name == 'account[corporation_name_kana]'){
        var billName = inputs[i].name.replace(/account\[corporation_name_kana\]/gi, 'account[invoice_corporation_name_kana]');
      }
      else if (inputs[i].name == 'account[corporation_zip]'){
        var billName = inputs[i].name.replace(/account\[corporation_zip\]/gi, 'account[invoice_zip]');
      }
      else if (inputs[i].name == 'account[person_family_name]'){
        var billName = inputs[i].name.replace(/account\[person_family_name\]/gi, 'account[invoice_family_name]');
      }
      else if (inputs[i].name == 'account[person_first_name]'){
        var billName = inputs[i].name.replace(/account\[person_first_name\]/gi, 'account[invoice_first_name]');
      }
      else if (inputs[i].name == 'account[person_first_name_kana]'){
        var billName = inputs[i].name.replace(/account\[person_first_name_kana\]/gi, 'account[invoice_first_name_kana]');
      }
      else if (inputs[i].name == 'account[person_family_name_kana]'){
        var billName = inputs[i].name.replace(/account\[person_family_name_kana\]/gi, 'account[invoice_family_name_kana]');
      }
      else if (inputs[i].name == 'account[person_phone]'){
        var billName = inputs[i].name.replace(/account\[person_phone\]/gi, 'account[invoice_phone]');
      }
      else if (inputs[i].name == 'account[person_email]'){
        var billName = inputs[i].name.replace(/account\[person_email\]/gi, 'account[invoice_email]');
      }
      else{
        var billName = inputs[i].name.replace(/account\[corporation/gi, 'account[invoice');
      }

      if($('#account_invoice_person_same_flag').prop( "checked" ) == true) {
        $('input[name="' + billName + '"]').val(inputs[i].value);
        $('input[name="' + billName + '"]').attr('readonly', true);
      }
    };
  });

  // count text in memo
  var memo_length = document.getElementsByClassName("acc_form")

  $('#account_memo').on('keyup', function() {
    $('#count_account_memo').text($(this).val().length + ' / 300 文字')
  });

  if(memo_length.length !== 0){
    $('#count_account_memo').text($('#account_memo').val().length + ' / 300 文字')
  }

// load more
  $('body').on('click', '#load-more-accounts', function() {
    let page = $(this).data('page')
    $.ajax({
      url: '/accounts?page=' + page,
      type: 'get',
      success: function(data){
        $('#load_more_tbody_acc').append($(data).find('#load_more_tbody_acc tr'));
        $('#button_load_more_td_acc').html($(data).find('#button_load_more_td_acc a'));
      }
    })
    return false;
  })

  $('#account_csv_file').change(function() {
    var filename = $('#account_csv_file').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    $('#account_csv_file_name').val(filename);
  });

  $('#account_application_pdf').change(function() {
    var filename = $('#account_application_pdf').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    $('#account_application_pdf_file_name').val(filename);
  });

  $("#btn-submit-csv-account").on("click",function () {
    $("#upload-image-account-csv_file").css('pointer-events', 'none');
    $("#upload-image-account-zip_file").css('pointer-events', 'none');
  })

  $('body').on('click', '#js-btn-rollback-import', function() {
    sweetAleartRollback();
  });

  function sweetAleartRollback() {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        $.ajax({
          method: 'DELETE',
          url: `/accounts/rollback_migration`
        });
      }
    });
  }

  // render referal code
  $('#account_invitation_partner_id').on('change', function() {
    $.ajax({
      type: 'GET',
      url: '/accounts/render_referral_code',
      data: {'partner_id': this.value},
      dataType: 'json',
      success: function(data) {
        $(".display_referral_code").text(data["referral_code"]);
      }
    });
  });
  check_hide_and_show_mark_required($("#account_contract_type_id").val())

  $("#account_contract_type_id").on("change", function () {
    check_hide_and_show_mark_required(this.value)
  });

  function check_hide_and_show_mark_required(type) {
    if (type == 1) {
      $(".span_required_optional").show();
    } else {
      $(".span_required_optional").hide();
    }
  }
});

