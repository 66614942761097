$(document).on("turbolinks:load", function() {
  $('body').on('click', '.js-btn-delete-cost', function() {
    sweetAleartDeleteStore($(this).data('id'));
  });

  function sweetAleartDeleteStore(id) {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        $.ajax({
          method: 'DELETE',
          url: `/costs/${id}`
        });
      }
    });
  }

  $('.mst_cost_show').on('click', '.button_update_cost', function() {
    var inputs = $('.mst-cost-concon .nested-fields:not([style]) input[class*="amount_tax_included_js"]'),
        count = 0;
    for (var i = 0; i < inputs.length; i++) {
      count += Number(inputs[i].value.replace(/,/g, ''));
    };

    $('#amount_tax_included').text(count.toLocaleString());

    var excluding_tax_inputs = $('.mst-cost-concon .nested-fields:not([style]) input[class*="amount_excluding_tax_js"]'),
        excluding_tax_count = 0;
    for (var i = 0; i < excluding_tax_inputs.length; i++) {
      excluding_tax_count += Number(excluding_tax_inputs[i].value.replace(/,/g, ''));
    };

    $('#amount_excluding_tax').text(excluding_tax_count.toLocaleString());
  });

  $('body').on('click', '#load-more-costs', function() {
    let page = $(this).data('page')
    $.ajax({
      url: '/costs?page=' + page,
      type: 'get',
      success: function(data){
        $('#load_more_tbody_dtrg').append($(data).find('#load_more_tbody_dtrg tr'));
        $('#button_load_more_tdrg').html($(data).find('#button_load_more_tdrg a'));
      }
    })
    return false;
  })

  let arrayKeyCode = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105],
      arrayKeyCodeFree = [8, 17, 37, 39, 46, 109, 189]

  $('.manual_input_invoice_amount_js').on('keydown', function(e) {
    if (e.keyCode == 9) {
      return;
    }

    let condition = false,
        lengthValue = 9;
    if (this.value.indexOf('-') == 0) {
      if (e.keyCode != 109 && e.keyCode != 189) {
        condition = true;
        lengthValue = 10
      }
    }else{
      if (this.value.length >= 1) {
        if (e.keyCode != 109 && e.keyCode != 189) {
          condition = true
        }
      }else{
        condition = true
      }
    }

    if (((arrayKeyCode.includes(e.keyCode) && this.value.length <= lengthValue) || arrayKeyCodeFree.includes(e.keyCode)) && condition == true) {
      let value = this.value.replace(/,/g, '');
      if (value != null && value != '' && isNaN(parseFloat(value)) == false) {
        this.value = parseFloat(value).toLocaleString('en-US', {
          style: 'decimal',
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        });
      }
    }else {
      return false;
    }
  });

  $('.manual_input_invoice_amount_js').on('keyup', function(e) {
    if (e.keyCode == 9) {
      return;
    }

    if ((arrayKeyCode.includes(e.keyCode) && this.value.length <= 11) || arrayKeyCodeFree.includes(e.keyCode)) {
      let value = this.value.replace(/,/g, '');
      if (value != null && value != '' && isNaN(parseFloat(value)) == false) {
        this.value = parseFloat(value).toLocaleString('en-US', {
          style: 'decimal',
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        });
      }
    }else{
      event.stopPropagation();
    }
  });

  if($('.manual_input_invoice_amount_js').length > 0){
    let size = $('.manual_input_invoice_amount_js').length
    for (let i = 0; i < size; i++){
      let value = $('.manual_input_invoice_amount_js')[i].value.replace(/,/g, '');

      if (value != null && value != '' && isNaN(parseFloat(value)) == false) {
        $('.manual_input_invoice_amount_js')[i].value = parseFloat(value).toLocaleString('en-US', {
          style: 'decimal',
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        });
      }
    }
  }

  $("#date_start_year option,#date_end_year option").each(function(){
    if($(this).val() != '' && $(this).val() != null && $(this).val() != 'undefined'){
      $(this).html($(this).val() + '年');
    }
  });

});
