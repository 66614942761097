$(function(){
  $("#auth_level_list tbody tr").click(function() {
     var selected = $(this).hasClass("highlight");
     $("#auth_level_list tbody tr").removeClass("highlight");
     if(!selected){
       $(this).addClass("highlight");
     }
  });

  $('body').on('click', '.js-btn-reset-auth_level', function() {
    if(areAllInputsEmpty('input.form-control') && areAllInputsEmpty('input.input')
      && areAllInputsEmpty('.select select')) {
      window.location.href = '/mst_auth_levels';
    } else {
      sweetAleartResetauth_level();
    }
  });

  $('body').on('click', '.js-btn-delete-auth_level', function() {
    sweetAleartDeleteauth_level($(this).data('id'));
  });

  function sweetAleartResetauth_level() {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        window.location.href = '/mst_auth_levels'
      }
    });
  }

  function sweetAleartDeleteauth_level(auth_level_id) {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        $.ajax({
          method: 'DELETE',
          url: `/mst_auth_levels/${auth_level_id}`
        });
      }
    });
  }
});
