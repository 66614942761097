$(document).on("turbolinks:load", function() {
  if ($('.js_invoice_detail .has_dowload_pdf').length <= 0) {
    $('.invoice .btn-download-all').hide();
  }

  $("#invoice_list tbody tr").click(function() {
     var selected = $(this).hasClass("highlight");
     $("#invoice_list tbody tr").removeClass("highlight");
     if(!selected){
       $(this).addClass("highlight");
     }
  });

  // load more
  $('body').on('click', '#load-more-invoices', function() {
    let url = $(this).data('url');

    $.ajax({
      url: url,
      type: 'get',
      success: function(data){
        $('#load_more_tbody_invoice').append($(data).find('#load_more_tbody_invoice tr'));
        $('#button_load_more_td_invoice').html($(data).find('#button_load_more_td_invoice a'));
        var rowCount = $('#load_more_tbody_invoice tr').length;
        $('#data_size').html("該当件数:" + rowCount + "件");
      }
    })

    return false;
  })

  // expand
  $(".js-invoice-detail-expand .icon-expand").on("click", function() {
    var $iconExpand = $(this).parent().find(".icon-expand");
    var class_name = "." + $(this).parent().find(".expand-invoice-detail").attr('data-id');

    if ($.inArray("fa-caret-down", $iconExpand[0].className.split(/\s+/)) != -1) {
      var classExpand = "fa-caret-up";
    } else {
      var classExpand = "fa-caret-down";
    }
    $iconExpand.removeClass("fa-caret-down fa-caret-up");
    $iconExpand.addClass(classExpand);
    $(this).parent().find(class_name).slideToggle();
  });

  // detail
  $('body').on('click', '.js_invoice_detail', function(event) {
    if (!$(event.target).hasClass('js_dowload_pdf') && !$(event.target).hasClass('special_td')){
      window.location.href = '/invoices/' + $(this).data('id');
    }
  });

  // amount
  let arrayKeyCode = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105],
      arrayKeyCodeFree = [8, 17, 37, 39, 46, 109, 189]

  $('.manual_input_invoice_amount_js').on('keydown', function(e) {
    if (e.keyCode == 9) {
      return;
    }

    let condition = false,
        lengthValue = 9;
    if (this.value.indexOf('-') == 0) {
      if (e.keyCode != 109 && e.keyCode != 189) {
        condition = true;
        lengthValue = 10
      }
    }else{
      if (this.value.length >= 1) {
        if (e.keyCode != 109 && e.keyCode != 189) {
          condition = true
        }
      }else{
        condition = true
      }
    }

    if (((arrayKeyCode.indexOf(e.keyCode) > -1 && this.value.length <= lengthValue) || arrayKeyCodeFree.indexOf(e.keyCode) > -1) && condition == true) {
      let value = this.value.replace(/,/g, '');
      if (value != null && value != '' && isNaN(parseFloat(value)) == false) {
        this.value = parseFloat(value).toLocaleString('en-US', {
          style: 'decimal',
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        });
      }
    }else {
      return false;
    }
  });

  $('.manual_input_invoice_amount_js').on('keyup', function(e) {
    if (e.keyCode == 9) {
      return;
    }

    if ((arrayKeyCode.indexOf(e.keyCode) > -1 && this.value.length <= 11) || arrayKeyCodeFree.indexOf(e.keyCode) > -1) {
      let value = this.value.replace(/,/g, '');
      if (value != null && value != '' && isNaN(parseFloat(value)) == false) {
        this.value = parseFloat(value).toLocaleString('en-US', {
          style: 'decimal',
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        });
      }
    }else{
      event.stopPropagation();
    }
  });

  if($('.manual_input_invoice_amount_js').length > 0){
    let size = $('.manual_input_invoice_amount_js').length
    for (let i = 0; i < size; i++){
      let value = $('.manual_input_invoice_amount_js')[i].value.replace(/,/g, '');

      if (value != null && value != '' && isNaN(parseFloat(value)) == false) {
        $('.manual_input_invoice_amount_js')[i].value = parseFloat(value).toLocaleString('en-US', {
          style: 'decimal',
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        });
      }
    }
  }

  $('body').on('click', '.js-btn-approved-invoice', function() {
    sweetAleartApprovedInvoice($(this));
  });

  function sweetAleartApprovedInvoice(element) {
    Swal.fire({
      title: element.data('year') + '年' + element.data('month') +'月分の請求内容を承認しますか？',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4D817DB1',
      cancelButtonColor: '#B40311',
      confirmButtonText: '承認する',
      cancelButtonText: 'キャンセル',
    }).then(function(result) {
      if (result.value) {
        element.hide();
        $.ajax({
          method: 'GET',
          url: element.data('url')
        }).done(function(data){
          element.after('<p>' + data['approval_time'] + ' に ' + data['approval_user'] + ' によって承認されました。</p>');
        })
      }
    });
  }

  //Re-Invoice
  $('body').on('click', '.js-re-invoice', function() {
    sweetReInvoice($(this));
  });

  function sweetReInvoice(element) {
    Swal.fire(swalReInvoice).then(function(result) {
      if (result.value) {
        $.ajax({
          method: 'GET',
          url: '/invoices/call_api_re_invoice?invoice_id=' + element.data('id')
        }).done(function() {
          location.reload();
        });
      }
    });
  }
});
