$(document).on("turbolinks:load", function() {
  // store
  if (typeof $("#store_thumbnail_1_url").attr('value') !== 'undefined') {
    $("#image-preview1").parent().css('background-image', 'url("' + $("#store_thumbnail_1_url").attr('value') + '")');
    $("#delete_image1").show();
  }
  if (typeof $("#thumbnail_url_temp_img_1").attr('value') !== 'undefined') {
    $("#image-preview1").parent().css('background-image', 'url("' + $("#thumbnail_url_temp_img_1").attr('value') + '")');
    $("#delete_image1").show();
  }
  if (typeof $("#store_thumbnail_2_url").attr('value') !== 'undefined') {
    $("#image-preview2").parent().css('background-image', 'url("' + $("#store_thumbnail_2_url").attr('value') + '")');
    $("#delete_image2").show();
  }
  if (typeof $("#thumbnail_url_temp_img_2").attr('value') !== 'undefined') {
    $("#image-preview2").parent().css('background-image', 'url("' + $("#thumbnail_url_temp_img_2").attr('value') + '")');
    $("#delete_image2").show();
  }
  if (typeof $("#store_thumbnail_3_url").attr('value') !== 'undefined') {
    $("#image-preview3").parent().css('background-image', 'url("' + $("#store_thumbnail_3_url").attr('value') + '")');
    $("#delete_image3").show();
  }
  if (typeof $("#thumbnail_url_temp_img_3").attr('value') !== 'undefined') {
    $("#image-preview3").parent().css('background-image', 'url("' + $("#thumbnail_url_temp_img_3").attr('value') + '")');
    $("#delete_image3").show();
  }

  //thumbnail_layout_store
  if (typeof $("#store_floor_map_2_url").attr('value') !== 'undefined') {
    $("#image-preview-floor-map-2-url").parent().css('background-image', 'url("' + $("#store_floor_map_2_url").attr('value') + '")');
    $("#delete_image_floor_map_2_url").show();
  }
  if (typeof $("#thumbnail_url_temp_img_floor_map_2_url").attr('value') !== 'undefined') {
    $("#image-preview-floor-map-2-url").parent().css('background-image', 'url("' + $("#thumbnail_url_temp_img_floor_map_2_url").attr('value') + '")');
    $("#delete_image_floor_map_2_url").show();
  }

  if (typeof $("#store_floor_map_url").attr('value') !== 'undefined') {
    $("#floor-map-preview").parent().css('background-image', 'url("' + $("#store_floor_map_url").attr('value') + '")');
    $(".get-floor-map").attr({href: "#", download: $("#store_floor_map_url").attr('value')});
    $("#delete_floor_map").show();
  }else{
    $(".get-floor-map").css('pointer-events', 'none')
  }
  if (typeof $("#thumbnail_url_temp_img_floor").attr('value') !== 'undefined') {
    $("#floor-map-preview").parent().css('background-image', 'url("' + $("#thumbnail_url_temp_img_floor").attr('value') + '")');
    $("#delete_floor_map").show();
  }

  // brand
  if (typeof $("#brand_icon_url").attr('value') !== 'undefined') {
    $("#image-preview-brand").parent().css('background-image', 'url("' + $("#brand_icon_url").attr('value') + '")');
    $("#delete_image_brand").show();
  }

  if (typeof $("#partner_brand_icon_url").attr('value') !== 'undefined') {
    $("#image-preview-brand_icon_url").parent().css('background-image', 'url("' + $("#partner_brand_icon_url").attr('value') + '")');
    $("#delete_brand_icon_url").show();
  }
  if (typeof $("#thumbnail_url_temp_brand_icon_url").attr('value') !== 'undefined') {
    $("#image-preview-brand_icon_url").parent().css('background-image', 'url("' + $("#thumbnail_url_temp_brand_icon_url").attr('value') + '")');
    $("#delete_brand_icon_url").show();
  }

  // 特定パートナー - ヘッダ画像
  if (typeof $("#header_image_url").attr('value') !== 'undefined') {
    $("#image-preview-header_image_url").parent().css('background-image', 'url("' + $("#header_image_url").attr('value') + '")');
    $("#delete_header_image_url").show();
  }

  if (typeof $("#partner_home_configuration_attributes_header_image_url").attr('value') !== 'undefined') {
    $("#image-preview-header_image_url").parent().css('background-image', 'url("' + $("#partner_home_configuration_attributes_header_image_url").attr('value') + '")');
    $("#delete_header_image_url").show();
  }
  if (typeof $("#thumbnail_url_temp_header_image_url").attr('value') !== 'undefined') {
    $("#image-preview-header_image_url").parent().css('background-image', 'url("' + $("#thumbnail_url_temp_header_image_url").attr('value') + '")');
    $("#delete_header_image_url").show();
  }

  // 特定パートナー - メイン画像
  if (typeof $("#main_image_url").attr('value') !== 'undefined') {
    $("#image-preview-main_image_url").parent().css('background-image', 'url("' + $("#main_image_url").attr('value') + '")');
    $("#delete_main_image_url").show();
  }

  if (typeof $("#partner_home_configuration_attributes_main_image_url").attr('value') !== 'undefined') {
    $("#image-preview-main_image_url").parent().css('background-image', 'url("' + $("#partner_home_configuration_attributes_main_image_url").attr('value') + '")');
    $("#delete_main_image_url").show();
  }
  if (typeof $("#thumbnail_url_temp_main_image_url").attr('value') !== 'undefined') {
    $("#image-preview-main_image_url").parent().css('background-image', 'url("' + $("#thumbnail_url_temp_main_image_url").attr('value') + '")');
    $("#delete_main_image_url").show();
  }

  // workspace
  // workspace_thumbnail_layout
  if (typeof $("#workspace_thumbnail_1_url").attr('value') !== 'undefined') {
    $("#image-preview1-workspace").parent().css('background-image', 'url("' + $("#workspace_thumbnail_1_url").attr('value') + '")');
    $("#delete_image_ws_1").show();
  }
  if (typeof $("#workspace_floor_map_2_url").attr('value') !== 'undefined') {
    $("#image-preview-ws-floor-map-2-url").parent().css('background-image', 'url("' + $("#workspace_floor_map_2_url").attr('value') + '")');
    $("#delete_image_ws_floor_map_2_url").show();
  }

  if (typeof $("#thumbnail_url_temp_img_1").attr('value') !== 'undefined') {
    $("#image-preview1-workspace").parent().css('background-image', 'url("' + $("#thumbnail_url_temp_img_1").attr('value') + '")');
    $("#delete_image_ws_1").show();
  }
  if (typeof $("#thumbnail_url_temp_img_floor_map_2_url").attr('value') !== 'undefined') {
    $("#image-preview-ws-floor-map-2-url").parent().css('background-image', 'url("' + $("#thumbnail_url_temp_img_floor_map_2_url").attr('value') + '")');
    $("#delete_image_ws_floor_map_2_url").show();
  }

  // solution
  if (typeof $("#solution_icon_on_url").attr('value') !== 'undefined') {
    $("#image-preview-solution-on").parent().css('background-image', 'url("' + $("#solution_icon_on_url").attr('value') + '")');
    $("#delete_image_solution_on").show();
  }

  if (typeof $("#solution_icon_off_url").attr('value') !== 'undefined') {
    $("#image-preview-solution-off").parent().css('background-image', 'url("' + $("#solution_icon_off_url").attr('value') + '")');
    $("#delete_image_solution_off").show();
  }

  // notice
  if (typeof $("#notice_image_url").attr('value') !== 'undefined') {
    $("#image-preview-notice").parent().css('background-image', 'url("' + $("#notice_image_url").attr('value') + '")');
  }

  if (typeof $("#notice_image_url").attr('value') !== 'undefined') {
    $(".js-download-image-notice").attr({href: $("#notice_image_url").attr('value'), download: $("#notice_image_url").attr('value')});
  }else{
    $(".js-download-image-notice").css('pointer-events', 'none')
    $("#delete_image_notice").css('pointer-events', 'none')
  }

  // js-download-pdf-notice
  if ($("#notice_pdf_value").attr('value') !== "") {
    $(".js-download-pdf").attr({href: $("#notice_pdf_value").attr('value'), download: $("#notice_pdf_value").attr('value')});
  }else{
    $(".js-download-pdf").css('pointer-events', 'none')
    $(".remove-pdf-notice").css('pointer-events', 'none')
  }

  // questionnaire
  if (typeof $("#questionnaire_image_url").attr('value') !== 'undefined') {
    $("#image-preview-questionnaire").parent().css('background-image', 'url("' + $("#questionnaire_image_url").attr('value') + '")');
  }

  if (typeof $("#questionnaire_image_url").attr('value') !== 'undefined') {
    $(".js-download-image-questionnaire").attr({href: $("#questionnaire_image_url").attr('value'), download: $("#questionnaire_image_url").attr('value')});
  }else{
    $(".js-download-image-questionnaire").css('pointer-events', 'none')
    $("#delete_image_questionnaire").css('pointer-events', 'none')
  }

  let countAttributeWayStore = $('.way-store-concon .store_way_stores_attributes_thumbnail').length;
  if(countAttributeWayStore > 0){
    for(let i = 0; i < countAttributeWayStore; i++){
      const id = '#store_way_stores_attributes_' + i + '_thumbnail';

      if (typeof $(id).attr('value') !== 'undefined') {
        $(id).siblings('.js--image-preview').css('background-image', 'url("' + $(id).attr('value') + '")');
        $(id).siblings('.delete_image_way_store').show();
      }

      if (typeof $(id).siblings('.hidden-input-image-way-store').attr('value') !== 'undefined') {
        $(id).siblings('.js--image-preview').css('background-image', 'url("' + $(id).siblings('.hidden-input-image-way-store').attr('value') + '")');
        $(id).siblings('.delete_image_way_store').show();
      }
    }
  }

  // brand
  $('#image-brand').click(function () {
    if ($('#divLargerImage').children().length == 0) {
      var $img = $(this).siblings().find('.js--image-preview')
      $('#divLargerImage').html($img.clone().height(500).width(500)).add($('#divOverlay')).fadeIn();
    }
  });

  $('#divLargerImage').add($('#divOverlay')).click(function () {
     $('#divLargerImage').add($('#divOverlay')).fadeOut(function () {
         $('#divLargerImage').empty();
     });
  });

  // solution
  $('#image-solution-on').click(function () {
    if ($('#divLargerImage').children().length == 0) {
      var $img = $(this).parent().siblings().find('.js--image-preview')
      $('#divLargerImage').html($img.clone().height(500).width(500)).add($('#divOverlay')).fadeIn();
    }
  });

  $('#image-solution-off').click(function () {
    if ($('#divLargerImage').children().length == 0) {
      var $img = $(this).parent().siblings().find('.js--image-preview')
      $('#divLargerImage').html($img.clone().height(500).width(500)).add($('#divOverlay')).fadeIn();
    }
  });

  // js-download-pdf-solution
  if (typeof $("#file-pdf").attr('value') !== "undefined") {
    $(".js-download-pdf").attr({href: $("#file-pdf").attr('value'), download: $("#file-pdf").attr('value')});
  }else{
    $(".js-download-pdf").css('pointer-events', 'none')
    $(".remove-pdf-solution").css('pointer-events', 'none')
  }

  $('#divLargerImage').add($('#divOverlay')).click(function () {
     $('#divLargerImage').add($('#divOverlay')).fadeOut(function () {
         $('#divLargerImage').empty();
     });
  });

  // show image page store
  $('#image-store-1, #image-store-2, #image-store-3, #image-store-floor-map-2-url, .show-image-way-store').click(function () {
    if ($('#divLargerImage').children().length == 0) {
      var $img = $(this).parent().siblings().find('.js--image-preview')
      $('#divLargerImage').html($img.clone().height(500).width(500)).add($('#divOverlay')).fadeIn();
    }
  });

  $('#image-ws-1, #js-view-ws-floor-map-2-url').click(function () {
    if ($('#divLargerImage').children().length == 0) {
      var $img = $(this).parent().siblings().find('.js--image-preview')
      $('#divLargerImage').html($img.clone().height(500).width(500)).add($('#divOverlay')).fadeIn();
    }
  });

  $('#image-brand-icon-url').click(function () {
    if ($('#divLargerImage').children().length == 0) {
      var $img = $(this).parent().siblings().find('.js--image-preview')
      $('#divLargerImage').html($img.clone().height(500).width(500)).add($('#divOverlay')).fadeIn();
    }
  });

  // 特定パートナー - ヘッダー画像 - イメージ
  $('#image-header_image_url').click(function () {
    if ($('#divLargerImage').children().length == 0) {
      var $img = $(this).parents('.select-area').find('.js--image-preview')
      $('#divLargerImage').html($img.clone().height(500).width(500)).add($('#divOverlay')).fadeIn();
    }
  });

  // 特定パートナー - メイン画像 - イメージ
  $('#image-main_image_url').click(function () {
    if ($('#divLargerImage').children().length == 0) {
      var $img = $(this).parents('.select-area').find('.js--image-preview')
      $('#divLargerImage').html($img.clone().height(500).width(500)).add($('#divOverlay')).fadeIn();
    }
  });

  $('.block_hidden_store_image').click(function () {
    if ($('#divLargerImage').children().length == 0) {
      var $img = $('.block_hidden_store_image').siblings('.js--image-preview')
      $('#divLargerImage').html($img.clone().height(500).width(500)).add($('#divOverlay')).fadeIn();
    }
  });

  $('#divLargerImage').add($('#divOverlay')).click(function () {
     $('#divLargerImage').add($('#divOverlay')).fadeOut(function () {
         $('#divLargerImage').empty();
     });
  });

  $('.image-floor-map').click(function(){
    $(this).parent().siblings('.show_floor_map').find('#store_floor_map_url').trigger('click');
  })

  $('.js--image-preview').click(function(){
    if ($(this).attr('disabled') || $(this).prop('disabled')) { return; }
    else {
      var id_select = $(this).find('div:first').attr('id') ? $(this).find('div:first').attr('id') : $(this).find('div:first').attr('class');
      switch (id_select) {
        case 'image-preview1':
          $('#store_thumbnail_1_url').trigger('click');
          break;
        case 'image-preview2':
          $('#store_thumbnail_2_url').trigger('click');
          break;
        case 'image-preview3':
          $('#store_thumbnail_3_url').trigger('click');
          break;
        case 'image-preview-floor-map-2-url':
          $('#store_floor_map_2_url').trigger('click');
          break;
        case 'image-preview-brand':
          $('#brand_icon_url').trigger('click');
          break;
        // 特定パートナー - ヘッダー画像
        case 'image-preview-header_image_url':
          $('#header_image_url').trigger('click');
          break;
        // 特定パートナー - メイン画像
        case 'image-preview-main_image_url':
          $('#main_image_url').trigger('click');
          break;
        case 'image-preview-notice':
          $('#notice_image_url').trigger('click');
          break;
        case 'image-preview1-workspace':
          $('#workspace_thumbnail_1_url').trigger('click');
          break;
        case 'image-preview-ws-floor-map-2-url':
          $('#workspace_floor_map_2_url').trigger('click');
          break;
        case 'image-preview-brand_icon_url':
          $('#partner_brand_icon_url').trigger('click');
          break;
        // 特定パートナー - ヘッダー画像
        case 'image-preview-header_image_url':
          $('#partner_home_configuration_attributes_header_image_url').trigger('click');
          break;
        // 特定パートナー - メイン画像
        case 'image-preview-main_image_url':
          $('#partner_home_configuration_attributes_main_image_url').trigger('click');
          break;
        case 'image-preview-way-store':
          $(this).siblings('.store_way_stores_attributes_thumbnail').trigger('click');
          break;
      }
    }
  });

  $('#upload-image-store-1').click(function(){
    $('#store_thumbnail_1_url').trigger('click');
  })

  $('#upload-image-store-2').click(function(){
    $('#store_thumbnail_2_url').trigger('click');
  })

  $('#upload-image-store-3').click(function(){
    $('#store_thumbnail_3_url').trigger('click');
  })

  $('#upload-image-store-floor-map-2-url').click(function(){
    $('#store_floor_map_2_url').trigger('click');
  })

  $('#upload-image-ws-1').click(function(){
    $('#workspace_thumbnail_1_url').trigger('click');
  })

  $('#js-upload-ws-floor-map-2-url').click(function(){
    $('#workspace_floor_map_2_url').trigger('click');
  })

  $('#upload-solution-on').click(function(){
    $('#solution_icon_on_url').trigger('click');
  })

  $('#upload-solution-off').click(function(){
    $('#solution_icon_off_url').trigger('click');
  })

  $('#upload-image-brand-icon-url').click(function(){
    $('#partner_brand_icon_url').trigger('click');
  })

  // 特定パートナー - ヘッダー画像 - アップロード
  $('#upload-image-header_image_url').click(function(){
    $('#partner_home_configuration_attributes_header_image_url').trigger('click');
  })

  // 特定パートナー - メイン画像 - アップロード
  $('#upload-image-main_image_url').click(function(){
    $('#partner_home_configuration_attributes_main_image_url').trigger('click');
  })

  $('#upload-image-workspace-csv_file').click(function(){
    $('#workspace_csv_file').trigger('click');
  })

  $('#upload-image-account-csv_file').click(function(){
    $('#account_csv_file').trigger('click');
  })

  $('#upload-image-account-zip_file').click(function(){
    $('#account_application_pdf').trigger('click');
  })

  $('#upload-image-user-csv_file').click(function(){
    $('#user_csv_file').trigger('click');
  })

  $('.upload-image-way-store').click(function(){
    $(this).parent().siblings('.box').find('.store_way_stores_attributes_thumbnail').trigger('click');
  })

  function initImageUpload(box) {
    let uploadField = box.querySelector('.image-upload');
    uploadField.addEventListener('change', getFile);

    function getFile(e) {
       let file = e.currentTarget.files[0];
       if (file !== undefined) {
           checkType(file);
       }
    }

    function previewImage(file){
      let thumb = box.querySelector('.js--image-preview'),
          reader = new FileReader(),
          bt_delete = box.querySelector('.delete'),
          remove_image = box.querySelector('.remove_image');

      reader.onload = function() {
        thumb.style.backgroundImage = 'url(' + reader.result + ')';
        remove_image.value = '';
      }
      reader.readAsDataURL(file);
      thumb.className += ' js--no-default';
      bt_delete.style.display = "inline-block";
    }

    function checkType(file){
      let imageType = /image.*/;
      if (!file.type.match(imageType)) {
        throw 'File is not an image';
      } else if (!file){
        throw 'No picture selected';
      } else {
        previewImage(file);
      }
    }
  }

  // initialize box-scope
  var boxes = document.querySelectorAll('.box');

  for (let i = 0; i < boxes.length; i++) {
    let box = boxes[i];
    initDropEffect(box);
    initImageUpload(box);
  }

  /// drop-effect
  function initDropEffect(box){
    let area, drop, areaWidth, areaHeight, maxDistance, dropWidth, dropHeight, x, y;

    // get clickable area for drop effect
    area = box.querySelector('.js--image-preview');
    area.addEventListener('click', fireRipple);

    function fireRipple(e){
      area = e.currentTarget
      // create drop
      if(!drop){
        drop = document.createElement('span');
        drop.className = 'drop';
        this.appendChild(drop);
      }
      // reset animate class
      drop.className = 'drop';

      // calculate dimensions of area (longest side)
      areaWidth = getComputedStyle(this, null).getPropertyValue("width");
      areaHeight = getComputedStyle(this, null).getPropertyValue("height");
      maxDistance = Math.max(parseInt(areaWidth, 10), parseInt(areaHeight, 10));

      // set drop dimensions to fill area
      drop.style.width = maxDistance + 'px';
      drop.style.height = maxDistance + 'px';

      // calculate dimensions of drop
      dropWidth = getComputedStyle(this, null).getPropertyValue("width");
      dropHeight = getComputedStyle(this, null).getPropertyValue("height");

      // calculate relative coordinates of click
      // logic: click coordinates relative to page - parent's position relative to page - half of self height/width to make it controllable from the center
      x = e.pageX - this.offsetLeft - (parseInt(dropWidth, 10)/2);
      y = e.pageY - this.offsetTop - (parseInt(dropHeight, 10)/2) - 30;

      // position drop and animate
      drop.style.top = y + 'px';
      drop.style.left = x + 'px';
      drop.className += ' animate';
      e.stopPropagation();

    }
  }

  $(document).ready(function() {
    $('#delete_image1').on('click', function(e) {
       var el = $('#store_thumbnail_1_url'),
           el1 = $('#remove_image_img_1');
       deleteImageStore(el, el1);
       $("#image-preview1").parent().css('background-image', '');
       $('#delete_image1').hide();
       $('#store-img-1').html('');
    });
    $('#delete_image2').on('click', function(e) {
       var el = $('#store_thumbnail_2_url'),
           el1 = $('#remove_image_img_2');
       deleteImageStore(el, el1);
       $("#image-preview2").parent().css('background-image', '');
       $('#delete_image2').hide();
       $('#store-img-2').html('');
    });
    $('#delete_image3').on('click', function(e) {
       var el = $('#store_thumbnail_3_url'),
           el1 = $('#remove_image_img_3');
       deleteImageStore(el, el1);
       $("#image-preview3").parent().css('background-image', '');
       $('#delete_image3').hide();
       $('#store-img-3').html('');
    });
    $('#delete_image_floor_map_2_url').on('click', function(e) {
       var el = $('#store_floor_map_2_url'),
           el1 = $('#remove_image_img_floor_map_2_url');
       deleteImageStore(el, el1);
       $("#image-preview-floor-map-2-url").parent().css('background-image', '');
       $('#delete_image_floor_map_2_url').hide();
       $('#store-img-floor-map-2-url').html('');
    });
    $('#delete_floor_map').on('click', function(e) {
       var el = $('#store_floor_map_url'),
           el1 = $('#remove_image_img_floor');
       deleteImageStore(el, el1);
       $("#floor-map-preview").parent().css('background-image', '');
       $('#delete_floor_map').hide();
       $('#fileinput').html('');
    });

    // remove image workspace
    $('#delete_image_ws_1').on('click', function(e) {
      var el = $('#workspace_thumbnail_1_url'),
           el1 = $('#remove_image_img_1');
      deleteImageStore(el, el1);
      $("#image-preview1-workspace").parent().css('background-image', '');
      $('#delete_image_ws_1').hide();
      $('#ws-img-1').html('');
    });

    $('#delete_image_ws_floor_map_2_url').on('click', function(e) {
      var el = $('#workspace_floor_map_2_url'),
           el1 = $('#remove_image_img_floor_map_2_url');
      deleteImageStore(el, el1);
      $("#image-preview-ws-floor-map-2-url").parent().css('background-image', '');
      $('#delete_image_ws_floor_map_2_url').hide();
      $('#js-ws-img-floor-map-2-url').html('');
    });

    // remove image solution icon on
    $('#delete_image_solution_on').on('click', function(e) {
      var el = $('#solution_icon_on_url'),
          el1 = $('#remove_image_img_on');
      deleteImageStore(el, el1);
      $("#image-preview-solution-on").parent().css('background-image', '');
      $('#delete_image_solution_on').hide();
      $('#img-on-input').html('');
    });

    // remove image solution icon off
    $('#delete_image_solution_off').on('click', function(e) {
      var el = $('#solution_icon_off_url'),
          el1 = $('#remove_image_img_off');
      deleteImageStore(el, el1);
      $("#image-preview-solution-off").parent().css('background-image', '');
      $('#delete_image_solution_off').hide();
      $('#img-off-input').html('');
    });

    // remove image brand
    $('#delete_brand_icon_url').on('click', function(e) {
      var el = $('#partner_brand_icon_url'),
          el1 = $('#remove_image_brand_icon_url');
      deleteImageStore(el, el1);
      $("#image-preview-brand_icon_url").parent().css('background-image', '');
      $('#delete_brand_icon_url').hide();
      $('#partner_brand_icon_url').html('');
    });

    // 特定パートナー - ヘッダー画像 - 削除
    $('#delete_header_image_url').on('click', function(e) {
      var el = $('#partner_home_configuration_attributes_header_image_url'),
          el1 = $('#remove_image_header_image_url');
      deleteImageStore(el, el1);
      $("#image-preview-header_image_url").parent().css('background-image', '');
      $('#delete_header_image_url').hide();
      $('#partner-img-header_image_url').html('');
    });

    // 特定パートナー - メイン画像 - 削除
    $('#delete_main_image_url').on('click', function(e) {
      var el = $('#partner_home_configuration_attributes_main_image_url'),
          el1 = $('#remove_image_main_image_url');
      deleteImageStore(el, el1);
      $("#image-preview-main_image_url").parent().css('background-image', '');
      $('#delete_main_image_url').hide();
      $('#partner-img-main_image_url').html('');
    });

    // way store
    $('.delete_image_way_store').on('click', function(e) {
       var el = $(this).siblings('.store_way_stores_attributes_thumbnail'),
           el1 = $(this).siblings('.remove_image_way_store');
       deleteImageStore(el, el1);
       $(this).siblings(".js--image-preview").css('background-image', '');
       $(this).hide();
       $(this).parent().parent().siblings().find('.text_name_file_img_way_store').html('');
    });

    function deleteImageStore(el, el1) {
      el.wrap('<form>').closest('form').get(0).reset();
      el.unwrap();
      el.attr('value', '');
      el1.wrap('<form>').closest('form').get(0).reset();
      el1.unwrap();
      el1.attr('value', 'remove');
    }
  });

  // remove image notice
  $('#delete_image_notice').on('click', function(e) {
    var $el = $('#notice_image_url');
    $el.wrap('<form>').closest('form').get(0).reset();
    $el.unwrap();
    $el.attr('value', '');
    $("#image-preview-notice").parent().css('background-image', '');
    $('.rm_notice_image').html(`画像を削除する<input value="remove" type="hidden" name="notice[image_url]]">`);
  });

  // remove image questionnaire
  $('#delete_image_questionnaire').on('click', function(e) {
    var $el = $('#questionnaire_image_url');
    $el.wrap('<form>').closest('form').get(0).reset();
    $el.unwrap();
    $el.attr('value', '');
    $("#image-preview-questionnaire").parent().css('background-image', '');
    $('.rm_questionnaire_image').html(`画像を削除する<input value="remove" type="hidden" name="questionnaire[image_url]]">`);
  });
});
