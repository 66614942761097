$(document).on("turbolinks:load", function() {
  // load more
  $('body').on('click', '#load-more-printer_useds', function() {
    let page = $(this).data('page')
    $.ajax({
      url: '/printer_useds?page=' + page,
      type: 'get',
      success: function(data){
        $('#load_more_tbody_printer_used').append($(data).find('#load_more_tbody_printer_used tr'));
        $('#button_load_more_td_printer_used').html($(data).find('#button_load_more_td_printer_used a'));
        var rowCount = $('#load_more_tbody_printer_used tr').length;
        $('#data_size').html("該当件数:" + rowCount + "件");
      }
    })
    return false;
  });
});
