$(document).on("turbolinks:load", function() {
  $('#workspace_thumbnail_1_url').change(function(e) {
    var filename = $('#workspace_thumbnail_1_url').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    var file = e.currentTarget.files[0];
    if(file.size > 1 * 1024 * 1024) {
      $('#workspace_thumbnail_1_url').val('');
      alert("サムネイル画像 が容量を超えています。");
      e.stopPropagation();
      return;
    }

    $('#ws-img-1').html(filename);
  });

  $('#workspace_floor_map_2_url').change(function(e) {
    var filename = $('#workspace_floor_map_2_url').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    var file = e.currentTarget.files[0];
    if(file.size > 5 * 1024 * 1024) {
      $('#workspace_floor_map_2_url').val('');
      alert("フロアマップ画像 が容量を超えています。");
      e.stopPropagation();
      return;
    }
    $('#js-ws-floor-map-2-url').html(filename);
  });

  $('#workspace_csv_file').change(function() {
    var filename = $('#workspace_csv_file').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    $('#workspace_csv_file_name').val(filename);
  });

  $("#btn-submit-csv").on("click",function () {
    $("#upload-image-workspace-csv_file").css('pointer-events', 'none');
  })

  $("#workspace_list tbody tr").click(function() {
     var selected = $(this).hasClass("highlight");
     $("#workspace_list tbody tr").removeClass("highlight");
     if(!selected){
       $(this).addClass("highlight");
     }
  });

  $('body').on('click', '.js-btn-reset-workspace', function() {
    window.location.href = '/workspaces';
  });

  $('body').on('click', '.js-btn-delete-workspace', function() {
    sweetAleartDeleteworkspace($(this).data('id'));
  });

  function sweetAleartResetworkspace() {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        window.location.href = '/workspaces'
      }
    });
  }

  function sweetAleartDeleteworkspace(workspace_id) {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        $.ajax({
          method: 'DELETE',
          url: `/workspaces/${workspace_id}`
        });
      }
    });
  }

  // logic select solution image
  $('.select-box').click(function(){
    var check = [$('#solutions_value').val()]
    if (check == "") {
      check = []
    }

    if($(this).prop("checked") == true){
      var solution_selected = $(this).attr('id')
      check.push(solution_selected)

      $('#solutions_value').attr('value', check)
    }
    else if($(this).prop("checked") == false){
      var solution_selected = $(this).attr('id')

      $.each( check, function( key, item ) {
        var arr = item.split(",")
        $.each( arr, function( key, value ) {
          if (value == solution_selected) {
            arr = $.grep(arr, function(i) {
              return i != solution_selected;
            });
          }
        });
        $('#solutions_value').attr('value', arr)
      });
    }
  });

  // logic select partner to render store
  $('#workspace_partner_id').on('change', function() {
    console.log(this.value);
    $('#select_partner').val(this.value);
    $('#select_store_id').html('');
    var workspace_id = $('#workspace_ws_id').val();
    
    $('#workspace_store_id').val('');
    
    $.ajax({
      type: 'GET',
      url: '/workspaces/render_store_name',
      data: {'partner_id': this.value, workspace_id: workspace_id},
      dataType: 'json',
      success: function(data) {
        var store_name = $('#select_store_id').val();
        // var ws_code = $('#workspace_code_value').val();

        $.each( data["store_names"], function( key, value ) {
          $('#select_store_id').append(`<option value="${value[1]}" selected = ${store_name==value[0] ? true : false}> ${value[0]}</option>`)
          $('.workspace_code').html(value[2]);
          $('#params_workspace_cd').val(value[2]);
        });

        var store_id = $('#select_store_id').val();
        store_to_ind_ws(store_id);
      }
    });
  });

  if($('.check_render_wp_edit_js').length > 0){
    $('#select_store_id').html('');
    var select_store = $('#workspace_partner_id').val();
    var store_id = $('#workspace_store_id').val();

    $.ajax({
      type: 'GET',
      url: '/workspaces/render_store_name',
      data: {'partner_id': select_store, 'store_id': store_id},
      dataType: 'json',
      success: function(data) {
        var ws_code = $('#workspace_code_value').val();

        $.each( data["store_names"], function( key, value ) {
          $('#select_store_id').append(`<option value="${value[1]}"> ${value[0]}</option>`)
          if (ws_code !== "") {
            $('.workspace_code').html(ws_code);
            $('#params_workspace_cd').val(ws_code);
          } else {
            $('.workspace_code').html(value[2]);
            $('#params_workspace_cd').val(value[2]);
          }
        });

        var store_name = $('#workspace_store_id').val();
        $('#select_store_id option[value="'+store_name+'"]').attr('selected', 'selected');
      }
    });
  }
  var partner_name = $('#select_partner').val();
  $('#workspace_partner_id option[value="'+partner_name+'"]').attr('selected', 'selected');


  // logic render partner > store in list workspace
  $('#q_store_id_in').on('change', function() {
    $.ajax({
      type: 'GET',
      url: '/workspaces/render_store_name',
      data: {'arr_store_id': this.value},
      dataType: 'json',
      success: function(data) {
        if (data["store_names"] == null || data["store_names"] == '') { return $('#select_store_name').append(`<option value=""></option>`); }

        data["store_names"].forEach(function(value) {
          $('#select_store_name').append(`<option value="` + value[1] + `">` + value[0] + `</option>`)
        });
      }
    });
  });

  // logic render individual ws by store
  $('#select_store_id').on('change', function() {
    $('#workspace_workspace_type_by_store_id').html('');
    var store_id_present = this.value
    var workspace_id = $('#workspace_ws_id').val();
    $.ajax({
      type: 'GET',
      url: '/workspaces/render_workspace_by_store',
      data: {'store_id': store_id_present, workspace_id: workspace_id},
      dataType: 'json',
      success: function(data) {
        var individual_workspace = $('#workspace_workspace_type_by_store_id').val();

        $.each( data["workspaces_type_by_store"], function( key, value ) {
          if(value[0] == "default"){
            $('#workspace_workspace_type_by_store_id').append(`<option value="${value[1]}" selected = ${individual_workspace==value[0] ? true : false}>${value[2]}</option>`)
          } else {
            $('#workspace_workspace_type_by_store_id').append(`<option value="${value[1]}" selected = ${individual_workspace==value[0] ? true : false}>${value[2]} (${value[0]})</option>`)
          }

          $('.workspace_code').html(value[3]);
          $('#params_workspace_cd').val(value[3]);
        });
        var ind_ws_id = $('#workspace_workspace_type_by_store_id').val();
        ind_ws_to_price(ind_ws_id)
      }
    });
  });

  function store_to_ind_ws (store_id) {
    $('#workspace_workspace_type_by_store_id').html('');
    var workspace_id = $('#workspace_ws_id').val();
    $.ajax({
      type: 'GET',
      url: '/workspaces/render_workspace_by_store',
      data: {'store_id': store_id, workspace_id: workspace_id},
      dataType: 'json',
      success: function(data) {
        var individual_workspace = $('#workspace_workspace_type_by_store_id').val();

        $.each( data["workspaces_type_by_store"], function( key, value ) {
          if(value[0] == "default"){
            $('#workspace_workspace_type_by_store_id').append(`<option value="${value[1]}" selected = ${individual_workspace==value[0] ? true : false}>${value[2]}</option>`)
          } else {
            $('#workspace_workspace_type_by_store_id').append(`<option value="${value[1]}" selected = ${individual_workspace==value[0] ? true : false}>${value[2]} (${value[0]})</option>`)
          }
        });

        var ind_ws_id = $('#workspace_workspace_type_by_store_id').val();
        ind_ws_to_price(ind_ws_id)
      }
    });
  }

  if($('.search-workspace').length > 0){
    $('#q_store_partner_id_eq').on('change', function () {
      var partner_id = $('#q_store_partner_id_eq').val();
      $('#q_store_id_eq').html('');
      $.ajax({
        type: 'GET',
        url: '/workspaces/render_store_by_partner',
        data: {'partner_id': partner_id},
        dataType: 'json',
        success: function(data) {
          $('#q_store_id_eq').append(`<option value="">指定しない</option>`);
          data["store"].forEach(function(value) {
            if ($('#q_store_id_eq').attr('value') == value[1]) {
              $('#q_store_id_eq').append(`<option value="` + value[1] + `" selected>` + value[0] + `</option>`)
            }else{
              $('#q_store_id_eq').append(`<option value="` + value[1] + `">` + value[0] + `</option>`)
            }
          });
        }
      });
    });

    if ($('#q_store_partner_id_eq').val() != undefined) {
      var partner_id = $('#q_store_partner_id_eq').val();
      $.ajax({
        type: 'GET',
        url: '/workspaces/render_store_by_partner',
        data: {'partner_id': partner_id},
        dataType: 'json',
        success: function(data) {
          data["store"].forEach(function(value) {
            if ($('#q_store_id_eq').attr('value') == value[1]) {
              $('#q_store_id_eq').append(`<option value="` + value[1] + `" selected>` + value[0] + `</option>`)
            }else{
              $('#q_store_id_eq').append(`<option value="` + value[1] + `">` + value[0] + `</option>`)
            }
          });
        }
      });
    }
  }

  // render price by individal_ws
  $('#workspace_workspace_type_by_store_id').on('change', function() {
    $.ajax({
      type: 'GET',
      url: '/workspaces/render_price_individual_workspace',
      data: {'individual_workspace_id': this.value},
      dataType: 'json',
      success: function(data) {
        $(".regular_price").text(data["ordinarily_price"] + "   円");
        $(".discount_price").text(data["special_price"] + "   円");
      }
    });
  });

  function ind_ws_to_price (ind_ws_id) {
    $(".regular_price").html('');
    $(".discount_price").html('');

    $.ajax({
      type: 'GET',
      url: '/workspaces/render_price_individual_workspace',
      data: {'individual_workspace_id': ind_ws_id},
      dataType: 'json',
      success: function(data) {
        $(".regular_price").text(data["ordinarily_price"] + "   円");
        $(".discount_price").text(data["special_price"] + "   円");
      }
    });
  }

  // load more
  $('body').on('click', '#load-more-workspaces', function() {
    let page = $(this).data('page')
    $.ajax({
      url: '/workspaces?page=' + page,
      type: 'get',
      success: function(data){
        $('#load_more_tbody_ws').append($(data).find('#load_more_tbody_ws tr'));
        $('#button_load_more_td_ws').html($(data).find('#button_load_more_td_ws a'));
        var rowCount = $('#load_more_tbody_ws tr').length;
        $('#data_size').html("該当件数:" + rowCount + "件");
      }
    })
    return false;
  })

  // count text in memo
  var memo_length = document.getElementsByClassName("form_workspace")

  $('#workspace_memo').on('keyup', function() {
    $('#ws_memo').text($(this).val().length + ' / 300 文字')
  });

  $('#workspace_summary').on('keyup', function() {
    $('#ws_summary').text($(this).val().length + ' / 40 文字')
  });

  $('#workspace_detail').on('keyup', function() {
    $('#ws_detail').text($(this).val().length + ' / 2000 文字')
  });

  if(memo_length.length !== 0){
    $('#ws_memo').text($('#workspace_memo').val().length + ' / 300 文字')
    $('#ws_summary').text($('#workspace_summary').val().length + ' / 40 文字')
    $('#ws_detail').text($('#workspace_detail').val().length + ' / 2000 文字')
  }
});
