$(function(){
  $("#partner_payoff_list tbody tr").click(function() {
     var selected = $(this).hasClass("highlight");
     $("#partner_payoff_list tbody tr").removeClass("highlight");
     if(!selected){
       $(this).addClass("highlight");
     }
  });

  // load more
  $('body').on('click', '#load-more-partner_payoffs', function() {
    let page = $(this).data('page')
    $.ajax({
      url: '/partner_payoffs?page=' + page,
      type: 'get',
      success: function(data){
        $('#load_more_tbody_partner_payoff').append($(data).find('#load_more_tbody_partner_payoff tr'));
        $('#button_load_more_td_partner_payoff').html($(data).find('#button_load_more_td_partner_payoff a'));
        var rowCount = $('#load_more_tbody_partner_payoff tr').length;
        $('#data_size').html("該当件数:" + rowCount + "件");
      }
    })
    return false;
  })
});
$(document).on("turbolinks:load", function() {
  $('#jsPartnerPP').on('change', function () {
    $.ajax({
      type: 'GET',
      url: '/stores/render_store_data_by_search',
      data: {'partner_id': this.value},
      dataType: 'json',
      success: function (data) {
        $("#jsPartnerBrandNamePP").val(data['js_brands']);
      }
    });
  });
});