// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("packs/sidebar")
require("packs/partner")
require("packs/workspace")
require("packs/store")
require("packs/system_staff")
require("packs/committee")
require("packs/account")
require("packs/user")
require("packs/solution")
require("packs/auth_level")
require("packs/upload_image")
require("packs/notice")
require("packs/invoice")
require("packs/sale")
require("packs/partner_payoff")
require("packs/preview_pdf")
require("packs/questionnaire")
require("packs/visit_history")
require("packs/book")
require("packs/mst_regularcost")
require("packs/cost")
require("packs/common_search")
require("packs/printer_used")
require("jquery-mask-plugin")
require("packs/send_mail")
import toastr from 'toastr'
window.toastr = toastr
$.jMaskGlobals.watchDataMask = true;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// import "bootstrap"
$(document).on("turbolinks:load", function() {
  var maskOptions = {
    onKeyPress: function(cep, e, field, options) {
      var masks = ["000-000-00000", "000-0000-0000"],
        digits = cep.replace(/[^0-9]/g, "").length,
        mask = digits <= 10 ? masks[0] : masks[1];
      $(".mask-phone").mask(mask, options);
    }
  };
  $.each($(".mask-phone"), function() {
    var masks = ["000-000-00000", "000-0000-0000"],
        digits = $(this).val().replace(/[^0-9]/g, "").length,
        mask = digits <= 10 ? masks[0] : masks[1];
      $(this).mask(mask, maskOptions);
  });

  $.each($(".mask-phone-list"), function() {
    console.log($(this).text());
    var masks = ["000-000-00000", "000-0000-0000"],
        digits = $(this).text().replace(/[^0-9]/g, "").length,
        mask = digits <= 10 ? masks[0] : masks[1];
      $(this).mask(mask, maskOptions);
  });
  // $(".mask-phone").mask("000-000-00000", maskOptions);
  $('.sort_link').attr('target', '_self');

  //confirm logout
  $('body').on('click', '.js-button-logout', function() {
    sweetAleartLogout();
  });

  function sweetAleartLogout() {
    Swal.fire(swalLogout).then(function(result) {
      if (result.value) {
        $.ajax({
          method: 'DELETE',
          url: `/system_staffs/sign_out`
        }).done(function() {
          location.reload();
        });
      }
    });
  }

  $(document).on('click', '.lock-important', function() {
      const attr_value = $(this).closest(".lock-field").find("input").attr('id');
      console.log(attr_value);
      if (this.classList.contains('fa-unlock-alt')) {
          $("#"+attr_value).addClass('disabled-important');
          $(this).removeClass('fa-unlock-alt').addClass('fa-lock');
      } else {
          $("#"+attr_value).removeClass('disabled-important');
          $(this).removeClass('fa-lock').addClass('fa-unlock-alt');
      }
  });
});
