$(document).on("turbolinks:load", function() {
  // $("#user_list tbody tr").click(function() {
  //    var selected = $(this).hasClass("highlight");
  //    $("#user_list tbody tr").removeClass("highlight");
  //    if(!selected){
  //      $(this).addClass("highlight");
  //    }
  // });

  $('body').on('click', '.js-btn-reset-user', function() {
    window.location.href = '/users';
  });

  $('body').on('click', '.js-btn-delete-user', function() {
    sweetAleartDeleteuser($(this).data('id'));
  });

  function sweetAleartDeleteuser(user_id) {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        $.ajax({
          method: 'DELETE',
          url: `/users/${user_id}`
        });
      }
    });
  }

  // render account
  $('#user_account_cd').on('keyup', function() {
    $.ajax({
      type: 'GET',
      url: '/users/render_account',
      data: {'account_cd': this.value},
      dataType: 'json',
      success: function(data) {
        $(".corporation_name").text(data["corporation_name"]);
        $('#user_account_id').val(data["account_id"]);
        $(".contract_type").text(data['contract_type']);
        $(".user_cd_js").text(data['account_cd']);

        let array = data["user_user_authority_id"],
            userUserAuthorityId = document.getElementById("user_user_authority_id");

        userUserAuthorityId.length = 0;
        for (var x in array) {
          userUserAuthorityId.options[userUserAuthorityId.options.length] = new Option(array[x], x, true, true);
        }
        getDataGroup1(data["account_id"]);
      }
    });
  });

  $('#user_csv_file').change(function() {
    var filename = $('#user_csv_file').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    $('#user_csv_file_name').val(filename);
  });

  $("#btn-submit-csv-user").on("click",function () {
    $("#upload-image-user-csv_file").css('pointer-events', 'none');
  })

  $('body').on('click', '#js-btn-rollback-import-user', function() {
    sweetAleartRollbackUser();
  });

  function sweetAleartRollbackUser() {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        $.ajax({
          method: 'DELETE',
          url: `/users/rollback_migration`
        });
      }
    });
  }

  // count text in memo
  var memo_length = document.getElementsByClassName("form_user")

  $('#user_memo').on('keyup', function() {
    $('#count_user_memo').text($(this).val().length + ' / 300 文字')
  });

  if(memo_length.length !== 0){
    $('#count_user_memo').text($('#user_memo').val().length + ' / 300 文字')
  }

  // load more
  $('body').on('click', '#load-more-users', function() {
    let url = $(this).data('url');

    $.ajax({
      url: url,
      type: 'get',
      success: function(data){
        $('#load_more_tbody_user').append($(data).find('#load_more_tbody_user tr'));
        $('#button_load_more_td_user').html($(data).find('#button_load_more_td_user a'));
        var rowCount = $('#load_more_tbody_user tr').length;
        $('#data_size').html("該当件数:" + rowCount + "件");
      }
    })

    return false;
  });
  $("#date_year_start option,#date_year_end option").each(function(){
    if($(this).val() != '' && $(this).val() != null && $(this).val() != 'undefined'){
      $(this).html($(this).val() + '年');
    }
  });

  $("#date_month_start option,#date_month_end option").each(function(){
    if($(this).val() != '' && $(this).val() != null && $(this).val() != 'undefined'){
      $(this).html($(this).val() + '月');
    }
  });

  $("#date_day_start option,#date_day_end option").each(function(){
    if($(this).val() != '' && $(this).val() != null && $(this).val() != 'undefined'){
      $(this).html($(this).val() + '日');
    }
  });

  $( "#date_month_start" ).change(function() {
    var cur_month = $( "#date_month_start" ).val();
    var cur_year = $( "#date_year_start" ).val();
    daysInMonth = new Date(cur_year, cur_month, 0).getDate();
    arr_days = Array.from({length: daysInMonth}, (_, i) => i + 1)

    $('#date_day_start').html(`<option value=""></option>`);
    $.each( arr_days, function( key, value ) {
      $('#date_day_start').append(`<option value="${value}">${value}日</option>`)
    });
  });

  $( "#date_year_start" ).change(function() {
    var cur_month = $( "#date_month_start" ).val();
    var cur_year = $( "#date_year_start" ).val();
    daysInMonth = new Date(cur_year, cur_month, 0).getDate();
    arr_days = Array.from({length: daysInMonth}, (_, i) => i + 1)

    $('#date_day_start').html(`<option value=""></option>`);
    $.each( arr_days, function( key, value ) {
      $('#date_day_start').append(`<option value="${value}">${value}日</option>`)
    });
    $( "#date_month_start" ).val("");
  })

  var selected_value = $('#q_selected_day_start').val();
  if (selected_value != undefined) {
    var cur_month = $( "#date_month_start" ).val();
    var cur_year = $( "#date_year_start" ).val();
    daysInMonth = new Date(cur_year, cur_month, 0).getDate();
    arr_days = Array.from({length: daysInMonth}, (_, i) => i + 1)

    $('#date_day_start').html(`<option value=""></option>`);
    $.each( arr_days, function( key, value ) {
      if (parseInt(selected_value) == value) {
        $('#date_day_start').append(`<option value="${value}" selected = "selected">${value}日</option>`)
      } else {
        $('#date_day_start').append(`<option value="${value}">${value}日</option>`)
      }
    });
  }



  $( "#date_month_end" ).change(function() {
    var cur_month = $( "#date_month_end" ).val();
    var cur_year = $( "#date_year_end" ).val();
    daysInMonth = new Date(cur_year, cur_month, 0).getDate();
    arr_days = Array.from({length: daysInMonth}, (_, i) => i + 1)

    $('#date_day_end').html(`<option value=""></option>`);
    $.each( arr_days, function( key, value ) {
      $('#date_day_end').append(`<option value="${value}">${value}日</option>`)
    });
  });

  $( "#date_year_end" ).change(function() {
    var cur_month = $( "#date_month_end" ).val();
    var cur_year = $( "#date_year_end" ).val();
    daysInMonth = new Date(cur_year, cur_month, 0).getDate();
    arr_days = Array.from({length: daysInMonth}, (_, i) => i + 1)

    $('#date_day_end').html(`<option value=""></option>`);
    $.each( arr_days, function( key, value ) {
      $('#date_day_end').append(`<option value="${value}">${value}日</option>`)
    });
    $( "#date_month_end" ).val("");
  })

  var selected_value = $('#q_selected_day_end').val();
  if (selected_value != undefined) {
    var cur_month = $( "#date_month_end" ).val();
    var cur_year = $( "#date_year_end" ).val();
    daysInMonth = new Date(cur_year, cur_month, 0).getDate();
    arr_days = Array.from({length: daysInMonth}, (_, i) => i + 1)

    $('#date_day_end').html(`<option value=""></option>`);
    $.each( arr_days, function( key, value ) {
      if (parseInt(selected_value) == value) {
        $('#date_day_end').append(`<option value="${value}" selected = "selected">${value}日</option>`)
      } else {
        $('#date_day_end').append(`<option value="${value}">${value}日</option>`)
      }
    });
  }

  $(".group1_change").on("change", function () {
    var list_options = [["", ""]];
    $.ajax({
      type: 'GET',
      url: '/users/get_group2_by_group1_id',
      data: {'group1_id': this.value},
      dataType: 'json',
      success: function (res) {
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            list_options.push(res.data[i]);
          }
        }
        appendDataGroup(list_options, 'user_group2_id')
      }
    });
  });

  function getDataGroup1(account_id) {
    var list_options = [["", ""]];
    $.ajax({
      type: 'GET',
      url: '/users/get_group1_by_account_id',
      data: {'account_id': account_id},
      dataType: 'json',
      success: function (res) {
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            list_options.push(res.data[i]);
          }
        }
        appendDataGroup(list_options, 'user_group1_id')
      }
    });
  }

  function appendDataGroup(lists, group2_id_el) {
    $("#" + group2_id_el).html("");
    for (let i = 0; i < lists.length; i++) {
      $("#" + group2_id_el).append(`<option value="${lists[i][1]}">${lists[i][0]}</option>`)
    }
  }
});
